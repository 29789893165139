import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";

const testimonials = [
  {
    name: "Prashant Keshri",
    field: "Data Science",
    company: "Jakson Green",
    feedback:
      "Edgent transformed my career! With no coding experience, I became a skilled data scientist through their program. After my BTech from IIT Dhanbad and MTech, I was lost, but Edgent guided me. Now, I'm an Assistant Manager in Data Science at Jakson Green. Highly recommend Edgent for career growth!",
    photo:
      "https://edgentai-public-data.s3.ap-south-1.amazonaws.com/prashant.jpeg",
  },
  {
    name: "Pushkar Raj",
    field: "Data Science",
    company: "Neuralgo.ai",
    feedback:
      "Edgent turned my career around, taking me from an IAS aspirant to a Senior Data Scientist at Ascendum Solutions. Despite my lack of coding experience, their outstanding teaching and referral programs were pivotal to my success. I highly recommend Edgent for anyone seeking to enter the tech industry.",
    photo:
      "https://edgentai-public-data.s3.ap-south-1.amazonaws.com/pushkar.jpeg",
  },
  {
    name: "Preethi P",
    field: "SDE",
    company: "Aris Investing Private Limited",
    feedback:
      "Edgent significantly boosted my career right after college. Their exceptional teaching and referral programs secured me a Senior Frontend Developer role at Aris Investing Private Limited. I highly recommend Edgent for anyone aiming to excel in the tech industry.",
    photo:
      "https://edgentai-public-data.s3.ap-south-1.amazonaws.com/preethi.jpeg",
  },
  {
    name: "Deepak Sharma",
    field: "SDE",
    company: "Perfios",
    feedback:
      "Edgent transformed my career from Support Engineer to Senior Software Development Engineer at Perfios. Their comprehensive program and excellent mentorship gave me the skills and confidence to succeed in software development. I highly recommend Edgent for anyone seeking a significant career change.",
    photo:
      "https://edgentai-public-data.s3.ap-south-1.amazonaws.com/deepak.jpeg",
  },
  {
    name: "Nikhil Gupta",
    field: "Data Science",
    company: "Tech Mahindra",
    feedback:
      "Edgent helped me successfully transition into Data Science at Tech Mahindra. Their comprehensive program and mentorship equipped me with essential skills in advanced analytics, machine learning, and data interpretation. I highly recommend Edgent for anyone aspiring to thrive in the dynamic field of data science.",
    photo:
      "https://edgentai-public-data.s3.ap-south-1.amazonaws.com/nikhil_gupta.jpeg",
  },
];

const MentorshipSection = () => {
  return (
    <Box
      sx={{
        py: 6,
        paddingBottom: "100px",
        width: "100%",
        px: 4,
      }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          color: "#0181BB",
          fontWeight: 600,
          mb: 6,
          fontFamily: "Be Vietnam Pro",
          fontSize: { xs: "32px", sm: "36px", md: "52px" },
        }}
      >
        Edgent Stories
      </Typography>
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        {testimonials.map((testimonial, index) => (
          <Grid
            item
            xs={12} // 1 card per row on extra-small screens
            sm={6} // 2 cards per row on small screens
            md={4} // 3 cards per row on medium screens
            lg={2.4} // 5 cards per row on large screens
            key={index}
          >
            <Card
              sx={{
                borderRadius: "8px",
                border: "1px solid #D5D5D5",
                padding: "32px, 24px, 32px, 24px",
                fontFamily: "Be Vietnam Pro",
                height: "100%", // Ensure the card stretches to full height
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                  <Avatar
                    src={testimonial.photo}
                    sx={{
                      width: 56,
                      height: 56,
                      mr: 2,
                      mb: testimonial.name === "Preethi P" ? 2 : 0,
                    }}
                  />
                  <Box>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ fontFamily: "Be Vietnam Pro" }}
                    >
                      {testimonial.name}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      sx={{ fontFamily: "Be Vietnam Pro" }}
                    >
                      {testimonial.field}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{
                        fontFamily: "Be Vietnam Pro",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {testimonial.company}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", fontFamily: "Be Vietnam Pro" }}
                >
                  {testimonial.feedback}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MentorshipSection;
