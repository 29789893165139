import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { CardBody } from "reactstrap";
import { Box } from "@mui/material";
import book from '../../assets/homepage/book.svg'
import building from '../../assets/homepage/building.svg'
import student from '../../assets/homepage/student.svg'
import suitcase from '../../assets/homepage/suitcase.svg'

function WhoIsItForContent() {
  const cardData = [
    { id: 1, title: "Graduates looking for a job in Tech", imgSrc: `${student}` },
    { id: 2, title: "University students targeting Tech roles for career", imgSrc: `${building}`},
    { id: 3, title: "Professionals who wish to pivot career to Tech", imgSrc: `${suitcase}` },
    { id: 4, title: "Tech professionals looking to upgrade their current role/company", imgSrc: `${book}` },
  ];

  return (
    <Box className="w-100">
      <Box className="py-4" sx={{ color: '#0181BB', fontSize: { xs: '32px', sm: '36px', md: '52px' }, fontWeight: 600 , textAlign: 'center', fontFamily: 'Be Vietnam Pro'}}>Who Is It For?</Box>
      <Box className="d-flex justify-content-center" sx={{fontFamily: 'Be Vietnam Pro'}}>
        <Row style={{width: '75%'}}>
          {cardData.map((card) => (
            <Col key={card.id} xs={12} sm={6} className="mb-3">
              <Card className="rounded" style={{ border: 'none', height: '140px', border: '1px solid #D5D5D5', borderRadius: '12px' }}>
                <CardBody className="d-flex justify-content-center align-items-center flex-column">
                  <Box className="mb-2">
                    <img src={card.imgSrc} alt={card.title} style={{ width: 32, height: 32 }} />
                  </Box>
                  <Box sx={{ fontSize: '16px', color: '#595A5F' , textAlign: 'center'}}>
                    {card.title}
                  </Box>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Box>
    </Box>
  );
}

export default WhoIsItForContent;
