import React from "react";
import { Container, Grid, Box, Typography, Link, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import logo from "../assets/homepage/edgentLogo.svg";

const Footer = () => {
  return (
    <Box component="footer" sx={{ py: 6, mt: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" mb={2}>
              <img
                src={logo}
                alt="Edgent Logo"
                style={{ height: "40px", marginRight: "10px" }}
              />
            </Box>
            <Typography
              variant="body1"
              sx={{
                color: "#202124",
                fontSize: "16px",
                width: "400px",
                fontFamily: "Be Vietnam Pro",
              }}
            >
              About Edgent: Edgent is a 1:1 Mentorship focused edtech company
              with a vision to help individuals in career success.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 600, fontFamily: "Be Vietnam Pro" }}
            >
              Quick Links
            </Typography>
            <ul style={{ listStyle: "none", padding: 0 }}>
              {[
                { text: "Home", path: "/" },
                { text: "Discussion Forum", path: "/" },
                { text: "Career Planning", path: "/" },
              ].map((item) => (
                <li
                  key={item.text}
                  style={{
                    paddingBottom: "8px",
                    fontFamily: "Be Vietnam Pro !important",
                  }}
                >
                  <Tooltip title="Coming soon">
                    <RouterLink
                      to={item.path}
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        fontFamily: "Be Vietnam Pro",
                      }}
                    >
                      {item.text}
                    </RouterLink>
                  </Tooltip>
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 600, fontFamily: "Be Vietnam Pro" }}
            >
              Contact Us
            </Typography>
            <Box>
              <Link
                href="mailto:core@edgent.ai"
                variant="body1"
                color="inherit"
                underline="none"
                style={{ fontFamily: "Be Vietnam Pro", display: 'flex', alignItems: 'center', marginBottom: '8px' }}
              >
                <EmailOutlinedIcon style={{ marginRight: '8px' }} />
                core@edgent.ai
              </Link>
              <Link
                href="tel:+918299463677"
                variant="body1"
                color="inherit"
                underline="none"
                style={{ fontFamily: "Be Vietnam Pro", display: 'flex', alignItems: 'center', marginBottom: '8px' }}
              >
                <PhoneOutlinedIcon style={{ marginRight: '8px' }} />
                +91 8299463676
              </Link>
              <Link
                href="https://wa.me/918299463677"
                variant="body1"
                color="inherit"
                underline="none"
                target="_blank"
                style={{ fontFamily: "Be Vietnam Pro", display: 'flex', alignItems: 'center' }}
              >
                <WhatsAppIcon style={{ marginRight: '8px' }} />
                WhatsApp Us
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Box
          mt={6}
          textAlign="center"
          sx={{ borderTop: "1px solid #D5D5D5", fontFamily: "Be Vietnam Pro" }}
          pt={3}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontFamily: "Be Vietnam Pro" }}
          >
            © 2024. Edgent. All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
