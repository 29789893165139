import React from 'react';
import { Box, Typography, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const steps = [
  {
    number: 1,
    title: "Profile Evaluation",
    summaryDescription: "Provide exact details of your career aspirations through a detailed form.",
    description: "We will conduct a thorough evaluation of your profile, including education, skills, and work experience, and understand your specific career plans."
  },
  {
    number: 2,
    title: "Meet Your Mentor",
    summaryDescription: "Attend a free session with a hand-picked mentor for a discussion.",
    description: "Meet your mentor in a session to discuss your goals and expectations, establishing a connection for your mentorship journey."
  },
  {
    number: 3,
    title: "Mentorship Journey",
    summaryDescription: "Start your long-term mentorship with your chosen mentor.",
    description: "Begin your mentorship journey with regular sessions and tailored guidance to achieve your career aspirations and personal growth."
  },
  {
    number: 4,
    title: "The Edgent World",
    summaryDescription: "You're introduced to the world of Edgent and its beyond mentorship offerings.",
    description: "Explore the Edgent community and its resources, including networking opportunities, workshops, and exclusive content for continuous learning."
  },
];

const HowItWorks = () => {
  return (
    <Box sx={{ width: '100%', my: 4 , p: {xs: 3, sm: 3}}}>
      <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} px={4}>
          <Box sx={{
            width: { xs: '90%', md: '40%' },
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            background: 'linear-gradient(80deg, rgba(255, 255, 255, 0) 30%, rgba(190, 235, 255, 0.5) 60%)',
            aspectRatio: '1/1',
            mb: { xs: 4, md: 0 },
            position: 'absolute', left: '0px',
            fontFamily: 'Be Vietnam Pro',
            display: { xs: 'none', md: 'flex' } // Hide on smaller screens
          }}>
            <Typography sx={{ color: '#0181BB', fontWeight: 600 , fontSize: '52px', fontFamily: 'Be Vietnam Pro'}}>
              How it works
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box sx={{ textAlign: 'center', mb: 4, display: { xs: 'block', md: 'none' } }}>
            <Typography variant="h4" sx={{ color: '#0181BB', fontWeight: 600, fontFamily: 'Be Vietnam Pro', fontSize: { xs: '32px', sm: '36px', md: '52px' } }}>
              How It Works
            </Typography>
          </Box>
          {steps.map((step, index) => (
            <Accordion 
              key={index} 
              defaultExpanded={index === 0}
              sx={{ 
                mb: 2,
                fontFamily: 'Be Vietnam Pro'
              }}
            >
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />} 
                aria-controls={`panel${index + 1}a-content`} 
                id={`panel${index + 1}a-header`}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ 
                    backgroundColor: '#E0F5FF', 
                    borderRadius: '50%', 
                    width: 40, 
                    height: 35, 
                    p:1,
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    fontFamily: 'Be Vietnam Pro',
                    mr: 2 
                  }}>
                    <Typography variant="body1" sx={{ fontWeight: 600, color: '#0181BB', fontFamily: 'Be Vietnam Pro' }}>
                      {step.number}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography variant="h6" sx={{ fontWeight: 600, fontFamily: 'Be Vietnam Pro', mb: 1 }}>
                      {step.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#595A5F', fontFamily: 'Be Vietnam Pro', mb: 1 }}>
                      {step.summaryDescription}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" sx={{ color: '#595A5F', fontFamily: 'Be Vietnam Pro', ml: 6 }}>
                  {step.description}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default HowItWorks;
