import React from "react";
import { Box, Grid, Typography, Card, CardContent } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import chat from "../assets/homepage/chat.svg";
import cv from "../assets/homepage/cv.svg";
import mentor from "../assets/homepage/mentor.svg";

const offerings = [
  {
    title: "Dedicated Mentor",
    description:
      "A dedicated mentor with 30-50 hours of focussed 1:1 learning sessions flexibly designed as per your schedule",
    title1: "Chat with Mentor anytime",
    description1: "A 24x7 chat window to connect with your mentor anytime",
    icon: mentor,
  },
  {
    title: "Personalized Learning",
    description: "A flexible learning roadmap curated in line with your specific career aspirations",
    title1: "Proprietary Content & Exams",
    description1:
      "Free proprietary subject content, quizzes and assignments, all aligning with your career path",
    icon: chat,
  },
  {
    title: "CV Curation & Review",
    description: "Free CV curation and CV review by top HRs in the industry",
    title1: "Free Mock Interviews",
    description1:
      "Complimentary mock interviews towards the end of mentorship to ensure success in your job hunt",
    title2: "Referrals & Networking",
    description2:
      "You’ll enter the Edgent Community, consisting of like-minded peers and industry experts, who will be happy to refer you for desired roles",
    icon: cv,
  },
];

const offeringsExtended = [
  {
    title: "Dedicated Mentor",
    description:
      "A dedicated mentor with 30-50 hours of focussed 1:1 learning sessions flexibly designed as per your schedule",
  },
  {
    title: "Chat with Mentor anytime",
    description: "A 24x7 chat window to connect with your mentor anytime",
  },
  {
    title: "Personalized Learning",
    description: "A flexible learning roadmap curated in line with your specific career aspirations",
  },
  {
    title: "Proprietary Content & Exams",
    description:
      "Free proprietary subject content, quizzes and assignments, all aligning with your career path",
  },
  {
    title: "CV Curation & Review",
    description: "Free CV curation and CV review by top HRs in the industry",
  },
  {
    title: "Free Mock Interviews",
    description:
      "Complimentary mock interviews towards the end of mentorship to ensure success in your job hunt",
  },
  {
    title: "Referrals & Networking",
    description:
      "You’ll enter the Edgent Community, consisting of like-minded peers and industry experts, who will be happy to refer you for desired roles",
  },
];

const EdgentOfferings = () => {
  // Use theme and media query to determine the screen size
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Choose data set based on screen size
  const dataToShow = isSmallScreen ? offeringsExtended : offerings;

  return (
    <Box
      sx={{
        width: "100%",
        textAlign: "center",
        my: 4,
        fontFamily: "Be Vietnam Pro",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: "#0181BB",
          fontWeight: 600,
          mb: 4,
          fontFamily: "Be Vietnam Pro",
          fontSize: { xs: "32px", sm: "36px", md: "52px" },
        }}
      >
        Edgent Offerings
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {dataToShow.map((offering, index) => (
          <Grid item xs={10} md={9} key={index}>
            <Card
              sx={{
                height: { xs: "auto", md: 460 },
                borderRadius: { xs: "10px", sm: "10px", md: "500px" },
                boxShadow: "none",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: "center",
                p: 2,
                fontFamily: "Be Vietnam Pro",
                background:
                  index === 1
                    ? "linear-gradient(90deg, rgba(190, 235, 255, 0.5) 50%, rgba(255, 255, 255, 1) 80%)"
                    : "linear-gradient(90deg, rgba(255, 255, 255, 1) 20%, rgba(190, 235, 255, 0.5) 50%)",
              }}
            >
              <CardContent
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  textAlign: { xs: "center", md: "left" },
                  fontFamily: "Be Vietnam Pro",
                  p: 3,
                }}
              >
                {index === 1 && !isSmallScreen ? (
                  <>
                    <Box
                      sx={{
                        width: { xs: "100%", md: "50%" },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={offering?.icon}
                        sx={{
                          height: { xs: 80, sm: 100, md: 150 }, // Responsive heights for xs, sm, and md screens
                          width: { xs: 80, sm: 100, md: 150 },
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: { xs: "100%", md: "50%" },
                        mt: { xs: 2, md: 0 },
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: "#202124",
                          fontSize: { xs: "20px", sm: "22px", md: "22px" },
                          fontFamily: "Be Vietnam Pro",
                        }}
                      >
                        {offering.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#595A5F",
                          fontSize: { xs: "16px", sm: "18px", md: "18px" },
                          fontFamily: "Be Vietnam Pro",
                        }}
                      >
                        {offering.description}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: "#202124",
                          fontSize: { xs: "20px", sm: "22px", md: "22px" },
                          fontFamily: "Be Vietnam Pro",
                        }}
                      >
                        {offering.title1}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#595A5F",
                          fontSize: { xs: "16px", sm: "18px", md: "18px" },
                          fontFamily: "Be Vietnam Pro",
                        }}
                      >
                        {offering.description1}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        width: { xs: "100%", md: index === 2 ? "95%" : "60%" },
                        py: 1,
                        ml: { md: 2 },
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: "#202124",
                          fontSize: { xs: "20px", sm: "22px", md: "22px" },
                          fontFamily: "Be Vietnam Pro",
                        }}
                      >
                        {offering.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#595A5F",
                          fontSize: { xs: "16px", sm: "18px", md: "18px" },
                          fontFamily: "Be Vietnam Pro",
                        }}
                      >
                        {offering.description}
                      </Typography>
                      {offering.title1 && (
                        <>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              color: "#202124",
                              fontSize: { xs: "20px", sm: "22px", md: "22px" },
                              fontFamily: "Be Vietnam Pro",
                            }}
                          >
                            {offering.title1}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#595A5F",
                              fontSize: { xs: "16px", sm: "18px", md: "18px" },
                              fontFamily: "Be Vietnam Pro",
                            }}
                          >
                            {offering.description1}
                          </Typography>
                        </>
                      )}
                      {offering.title2 && (
                        <>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              color: "#202124",
                              fontSize: { xs: "20px", sm: "22px", md: "22px" },
                              fontFamily: "Be Vietnam Pro",
                            }}
                          >
                            {offering.title2}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#595A5F",
                              fontSize: { xs: "16px", sm: "18px", md: "18px" },
                              fontFamily: "Be Vietnam Pro",
                            }}
                          >
                            {offering.description2}
                          </Typography>
                        </>
                      )}
                    </Box>
                    {!isSmallScreen && (
                      <Box
                        sx={{
                          width: { xs: "100%", md: "40%" },
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mt: { xs: 2, md: 0 },
                        }}
                      >
                        <Box
                          component="img"
                          src={offering?.icon}
                          sx={{
                            height: { xs: 80, sm: 100, md: 150 }, // Responsive heights for xs, sm, and md screens
                            width: { xs: 80, sm: 100, md: 150 },
                          }}
                        />
                      </Box>
                    )}
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EdgentOfferings;
